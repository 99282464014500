@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./assets/styles/toastify.css";
@import "./assets/styles/icons.css";

html {
  scroll-behavior: smooth;
}

body {
  @apply text-chiper-dark-blue overflow-x-hidden;
}

section {
  @apply py-40;
}

.container {
  @apply grid
  grid-cols-12
  gap-2
  max-w-chiper-1440
  px-8;
}
.containerTerms {
  @apply grid
  smm:grid-cols-12
  gap-2
  max-w-chiper-1440
  smm:px-8
  px-2;
}

h1 {
  @apply font-chiper-bold
  text-chiper-h1
  leading-chiper-h1
  tracking-chiper-h1;
}

p {
  @apply font-chiper-regular
  text-chiper-p
  leading-chiper-p;
}

strong {
  @apply font-chiper-bold;
}

/* Date picker styles */

.react-datepicker {
  border: none !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.react-datepicker__header {
    background-color: white !important;
    border-bottom: none !important;
}
.react-datepicker__triangle{
  display: none !important;
}

.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: black !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  border-radius: 100% !important;
  background-color: #00D27C !important;
}


.react-multi-carousel-dot button{
  background:#bdbdbd !important;
  border:none !important;
}
.react-multi-carousel-dot--active button{
  border-color: none !important;
  background:red !important}

.imgIluminacion {
  max-width: none;
}

.underlineChiper{
    border-bottom: 10px solid red;
    display: inline-block;
    line-height: 1;
}
